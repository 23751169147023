console.log("%c" + 'Ваш браузер – ' + navigator.sayswho, "color:forestgreen;font-size:16px;")

$('input[name="phone"]').mask '+7 999 999 99 99'

$('input[type="file"]').each ->
  $(this).styler({
    filePlaceholder: $(this).data('placeholder')
    fileBrowse: ''
  })

$('input[type=number]:not(.unstyled), input[type=checkbox]:not(.unstyled), input[type=radio]:not(.unstyled)').styler()



scene = document.getElementById('flying-cookies');
if scene
  parallaxInstance = new Parallax(scene);



$('.news-page-bg').each () ->
  if ($(this).innerHeight() > 1800)
    $(this).addClass('tall')


$('.video-start-js').on 'click', () ->
  $(this).closest('.video-wrapper').addClass('show')
  iframe = $(this).closest('.video-wrapper').find('iframe')
  if iframe.attr('src') == ''
    iframe.attr('src', iframe.data('src') + '?' + iframe.data('settings'));
  iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  true



beginAC = 80
endAC = 320
beginB = 80
endB = 320

inAC = (s) ->
  s.draw('80% - 240', '80%', 0.3, {
    delay: 0.1
    callback: () ->
      inAC2(s)
  })

inAC2 = (s) ->
  s.draw('100% - 545', '100% - 305', 0.6, {
    easing: ease.ease('elastic-out', 1, 0.3)
  })

inB = (s) ->
  s.draw(beginB - 60, endB + 60, 0.1, {
    callback: () ->
      inB2(s)
  })

inB2 = (s) ->
  s.draw(beginB + 120, endB - 120, 0.3, {
    easing: ease.ease('bounce-out', 1, 0.3)
  })

outAC = (s) ->
  s.draw('90% - 240', '90%', 0.1, {
    easing: ease.ease('elastic-in', 1, 0.3),
    callback: () ->
      outAC2(s)
  })

outAC2 = (s) ->
  s.draw('20% - 240', '20%', 0.3, {
    callback: () ->
      outAC3(s)
  })

outAC3 = (s) ->
  s.draw(beginAC, endAC, 0.7, {
    easing: ease.ease('elastic-out', 1, 0.3)
  })

outB = (s) ->
  s.draw(beginB, endB, 0.7, {
    delay: 0.1,
    easing: ease.ease('elastic-out', 2, 0.4)
  })

pathA = document.getElementById('pathA')
pathB = document.getElementById('pathB')
pathC = document.getElementById('pathC')
segmentA = new Segment(pathA, beginAC, endAC)
segmentB = new Segment(pathB, beginB, endB)
segmentC = new Segment(pathC, beginAC, endAC)
trigger = document.getElementById('navTrigger')
toCloseIcon = true
dummy = document.getElementById('mainNav')
wrapper = document.getElementById('menuIconWrapper')

wrapper.style.visibility = 'visible';

trigger.onclick = () ->
  if toCloseIcon
    inAC(segmentA);
    inB(segmentB);
    inAC(segmentC);
    dummy.className = 'main-nav main-nav-open';
  else
    outAC(segmentA);
    outB(segmentB);
    outAC(segmentC);
    dummy.className = 'main-nav';

  toCloseIcon = !toCloseIcon;

if ($(window).scrollTop() > 0)
  $('header').addClass('sticked')
else
  $('header').removeClass('sticked')

$(window).scroll ->
  if ($(window).scrollTop() > 0)
    $('header').addClass('sticked')
  else
    $('header').removeClass('sticked')

freeze = false
GallerySliderInit = (event) ->
  gallery = $(event.target)
  gallery.find('.slick-slide.slick-cloned').find('a.gallery-item').on 'click', (e) ->
    $this = $(this)
    gallery.find('.slick-slide:not(.slick-cloned)').find('a.gallery-item').each ->
      if $this.attr('href') == $(this).attr('href')
        $(this).trigger('click')
    e.preventDefault()
    true
  gallery.find('.slick-slide:not(.slick-cloned)').find('a.gallery-item').magnificPopup({
    type: 'image'
    mainClass: 'mfp-zoom-in'
    fixedContentPos: false
    fixedBgPos: true
    overflowY: 'auto',
    closeBtnInside: true
    preloader: false
    midClick: true
    tClose: 'Закрыть (Esc)'
    tLoading: 'Загрузка изображения #%curr%...'
    gallery: {
      tPrev: 'Назад (Клавиша ←)'
      tNext: 'Вперед (Клавиша →)'
      tCounter: '%curr% / %total%'
      enabled: true
      navigateByImgClick: true
      preload: [0, 1] # Will preload 0 - before current, and 1 after the current image
    }
    image: {
      tError: '<a href="%url%">Изображение #%curr%</a> недоступно.'
      titleSrc: (item) ->
        title = if typeof item.el.data('title') == 'string' then item.el.data('title') else ''
        description = if typeof item.el.data('description') == 'string' then item.el.data('description') else ''
        title + '<small>' + description + '</small>'
    }
    callbacks: {
      beforeOpen: ->
        $('html').addClass('popup-open')
        freeze = true
        true
      afterClose: ->
        $('html').removeClass('popup-open')
        freeze = false
        true
      imageLoadComplete: ->
        $this = this
        setTimeout ->
          $this.wrap.addClass('mfp-image-loaded')
          true
        , 16
        true
      open: ->
        $.magnificPopup.instance.next = () ->
          $this = this
          $this.wrap.removeClass('mfp-image-loaded')
          setTimeout ->
            $.magnificPopup.proto.next.call($this)
            true
          , 120
          true
        $.magnificPopup.instance.prev = () ->
          $this = this
          $this.wrap.removeClass('mfp-image-loaded')
          setTimeout ->
            $.magnificPopup.proto.prev.call($this)
            true
          , 120
          true
        true
    }
  })



largeSlider = $('#aboutLargeSlider')
if largeSlider.length > 0
  largeSlider.on 'init', (event) ->
    GallerySliderInit(event)
    true
  largeSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev slick-arrow"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next slick-arrow"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof largeSlider.data('autoplay') == 'boolean' then largeSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 2
    slidesToScroll: 2
  })
  largeSlider.on 'breakpoint', (event) ->
    GallerySliderInit(event)
    true

smallSlider = $('#aboutSmallSlider')
if smallSlider.length > 0
  smallSlider.on 'init', (event) ->
    GallerySliderInit(event)
    true
  smallSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev slick-arrow"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next slick-arrow"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof smallSlider.data('autoplay') == 'boolean' then smallSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 7
    slidesToScroll: 7
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 5
          slidesToScroll: 5
        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 3
          slidesToScroll: 3
        }
      }
      {
        breakpoint: 480
        settings: {
          slidesToShow: 1
          slidesToScroll: 1
        }
      }

    ]
  })
  smallSlider.on 'breakpoint', (event) ->
    GallerySliderInit(event)
    true




mainSlider = $('#mainSlider')
if mainSlider.length > 0
  mainSlider.slick({
    swipe: true
    draggable: true
    dots: true
    dotsClass: 'slick-dots main-slider-dots list-unstyled'
    arrows: false
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof mainSlider.data('autoplay') == 'boolean' then mainSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 1
    slidesToScroll: 1

  })

partnersSlider = $('#partnersSlider')
if partnersSlider.length > 0
  partnersSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots main-slider-dots list-unstyled'
    arrows: false
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof partnersSlider.data('autoplay') == 'boolean' then partnersSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 6
    slidesToScroll: 6
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 3
          slidesToScroll: 3
        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 2
          slidesToScroll: 2
          rows: 2
        }
      }


    ]
  })

brandSlider = $('#brandSlider')
if brandSlider.length > 0
  brandSlider.slick({
    swipe: true
    draggable: true
    dots: true
    dotsClass: 'slick-dots main-slider-dots list-unstyled'
    arrows: false
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof brandSlider.data('autoplay') == 'boolean' then brandSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 3
    slidesToScroll: 3
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 2
          slidesToScroll: 2
        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 1
          slidesToScroll: 1
        }
      }


    ]

  })

docsSlider = $('.about-docs-slider-js')
if docsSlider.length > 0
  docsSlider.each ->
    $(this).slick({
      swipe: true
      draggable: true
      dots: false
      dotsClass: 'slick-dots main-slider-dots list-unstyled'
      arrows: true
      prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
      nextArrow: '<button type="button" class="slick-next"><span></span></button>'
      infinite: true
      pauseOnHover: false
      fade: false
      cssEase: 'ease-in-out'
      vertical: false
      centerMode: false
      centerPadding: '0px'
      speed: 750
      autoplay: if typeof $(this).data('autoplay') == 'boolean' then $(this).data('autoplay') else true
      autoplaySpeed: 5000
      slidesToShow: 3
      slidesToScroll: 3
      responsive: [
        {
          breakpoint: 992
          settings: {
            slidesToShow: 2
            slidesToScroll: 2
          }
        }
        {
          breakpoint: 768
          settings: {
            slidesToShow: 1
            slidesToScroll: 1
          }
        }


      ]

    })


ourParnterSlider = $('#ourParnterSlider')
if ourParnterSlider.length > 0
  ourParnterSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots main-slider-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof ourParnterSlider.data('autoplay') == 'boolean' then ourParnterSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 3
    slidesToScroll: 3
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 2
          slidesToScroll: 2
        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 1
          slidesToScroll: 1
        }
      }


    ]

  })




if $('#tabs').length > 0
  $('#tabs-list').find('li').on 'click', () ->
    $(this).addClass('active').siblings().removeClass('active')
    target = '#' + $(this).data('target')
    $(target).addClass('active').siblings().removeClass('active')

window.magnificPopup = null
bottonForm = false
popupInit = (button) ->
  magnificPopup = $.magnificPopup.instance
  position = $(window).scrollTop()

  magnificPopup.open
    items:
      src: button.data('target')
      trigger: button
    type: 'inline'
    fixedContentPos: false
    fixedBgPos: true
    overflowY: 'auto'
    closeBtnInside: true
    preloader: false
    removalDelay: 500
    tClose: 'Закрыть (Esc)'
    midClick: true
    callbacks:

      change: ->
        $(this.content).find('.js-title').text(this.currItem.data.trigger.data('title'));
        $(this.content).find('.js-theme').val(this.currItem.data.trigger.data('theme'));

        $(this.content).find('form').on 'reset', ->
          magnificPopup.close()
          true

        $(this.content).find('form').on 'submit', (e) ->
          if !this.currItem.data.trigger.data('onsubmit')
            magnificPopup.close()
          else
            e.preventDefault()
            eval(this.currItem.data.trigger.data('onsubmit'))
          true
        true

      beforeOpen: ->
        this.st.mainClass = this.items[0].trigger.data('effect')
        $('html').addClass('popup-open')
        true

      afterClose: ->
        $('html').removeClass('popup-open')
        $(window).scrollTop(position)
        bottonForm = false
        true

  true

oneVacancyInit = () ->

  $('.vacancy-button-js').on 'click', (e) ->
    e.preventDefault()
    e.stopPropagation()
    bottonForm = false
    magnificPopup = $.magnificPopup.instance
    position = $(window).scrollTop()

    magnificPopup.open
      items:
        src: '/popupvacancy.html?id=' + $(this).data('id')
        trigger: $(this)
      type: 'ajax'
      tClose: 'Закрыть (Esc)'
      tLoading: 'Загрузка...'
      callbacks: {
        parseAjax: (mfpResponse) ->
  #  // mfpResponse.data is a "data" object from ajax "success" callback
  #  // for simple HTML file, it will be just String
  #  // You may modify it to change contents of the popup
  #  // For example, to show just #some-element:
  #  // mfpResponse.data = $(mfpResponse.data).find('#some-element');

  #  // mfpResponse.data must be a String or a DOM (jQuery) element
  #        console.log('Ajax content loaded:', mfpResponse)
        ajaxContentAdded: () ->
  #      // Ajax content is loaded and appended to DOM

          $('[data-trigger="popup"]').on 'click', (e) ->
            e.preventDefault()
            e.stopPropagation()
            bottonForm = $(this)
            magnificPopup.close()

        change: ->



        beforeOpen: ->
          this.st.mainClass = this.items[0].trigger.data('effect')
          $('html').addClass('popup-open')
          true

        afterClose: ->
          $('html').removeClass('popup-open')
          $(window).scrollTop(position)
          if bottonForm
            popupInit(bottonForm)
          true

      }

oneVacancyInit()

$('.company-button-js').on 'click', (e) ->
  e.preventDefault()
  e.stopPropagation()
  bottonForm = false
  magnificPopup = $.magnificPopup.instance
  position = $(window).scrollTop()

  magnificPopup.open
    items:
      src: '/popupcompany.html?id=' + $(this).data('id')
      trigger: $(this)
    type: 'ajax'
    tClose: 'Закрыть (Esc)'
    tLoading: 'Загрузка...'
    callbacks: {
      parseAjax: (mfpResponse) ->
#  // mfpResponse.data is a "data" object from ajax "success" callback
#  // for simple HTML file, it will be just String
#  // You may modify it to change contents of the popup
#  // For example, to show just #some-element:
#  // mfpResponse.data = $(mfpResponse.data).find('#some-element');

#  // mfpResponse.data must be a String or a DOM (jQuery) element
#        console.log('Ajax content loaded:', mfpResponse)
      ajaxContentAdded: () ->
#      // Ajax content is loaded and appended to DOM
#        console.log(this.content)
        oneVacancyInit()
        $('[data-trigger="popup"]').on 'click', (e) ->
            e.preventDefault()
            e.stopPropagation()
            bottonForm = $(this)
            magnificPopup.close()



      change: ->



      beforeOpen: ->
        this.st.mainClass = this.items[0].trigger.data('effect')
        $('html').addClass('popup-open')
        true

      afterClose: ->
        $('html').removeClass('popup-open')
        $(window).scrollTop(position)
        if bottonForm
          popupInit(bottonForm)
        true

    }
